import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import {USER_CAPABILITY, USER_FEATURES} from '@/const';

export const initialState = {
  user: null,
  channel_key: null,
  subdoman: null,
  api_key: null,
  is_geo_blocked: false,
  capabilities: {
    [USER_CAPABILITY.watch]: false,
    [USER_CAPABILITY.download]: false,
    [USER_CAPABILITY.clipping]: false,
    [USER_CAPABILITY.download_clip]: false,
    [USER_CAPABILITY.no_ads]: false,
  },
  featureCount: {
    [USER_FEATURES.CLIPS]: 0,
    [USER_FEATURES.DOWNLOADS]: 0,
  },
};

export default {
  state: () => ({...initialState}),
  mutations,
  actions,
  getters,
};
