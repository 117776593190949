import mutations from './mutations';
import actions from './actions';

export const initialState = {
  eventCategories: [],
  eventCategoriesById: {},
};

export default {
  state: () => ({...initialState}),
  mutations,
  actions,
};
