import {eventTimeObject, eventTitle} from '@/league/components/EventCards/eventMethods';
import {ALL_CLIPS} from '@/store/getterTypes';

export default {
  [ALL_CLIPS]: (state) => {
    const allClips = state.allClips || [];
    if (allClips.length) {
      return allClips.map((clip) => {
        const event = clip.event;
        const time = eventTimeObject(event);
        return {
          ...clip,
          clipEventTitle: `${eventTitle(event)} ${time.month} ${time.day} ${
            time.hh
          }`,
        };
      });
    }
    return [];
  },
};
