import {UPDATE_CLIPS, UPDATE_ALL_CLIPS} from '../mutationTypes';

export default {
  [UPDATE_CLIPS](state, {data}={}) {
    if (data) {
      state.clips = data;
    }
  },
  [UPDATE_ALL_CLIPS](state, data = []) {
    state.allClips = data;
  },
};
