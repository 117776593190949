export const STORAGE_KEYS = {
  DISABLED_PREROLLS: 'disabledPrerolls',
};

/**
 * Sets an item in the local storage.
 *
 * @param {string} key - The key under which the value is stored.
 * @param {*} value - The value to be stored. It will be stringified to JSON.
 * @return {void}
 */
export function setStorageItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

/**
 * Retrieves an item from the local storage.
 *
 * @param {string} key - The key of the item to retrieve.
 * @return {*} - The parsed value from the local storage.
 * If the key does not exist, it returns null.
 */
export function getStorageItem(key) {
  return JSON.parse(localStorage.getItem(key));
}

/**
 * Removes an item from the local storage.
 *
 * @param {string} key - The key of the item to remove.
 * @return {void}
 */
export function removeStorageItem(key) {
  localStorage.removeItem(key);
}
