import {GET_CHANNEL_SLIDES_FOR_QUERY, GET_FAVORITES_IDS_RECURSIVELY} from '@/store/getterTypes';
import {generateIdsObj, recursivelyGetProps} from '@/utils/helpers';
import {FAVORITE_TYPES, SLIDES_TYPES} from '@/const';

export default {
  [GET_FAVORITES_IDS_RECURSIVELY]({favorite, participant, event}) {
    const favorites = favorite.favorites;
    const typed = {
      [FAVORITE_TYPES.participant]: generateIdsObj(participant.participantsList),
      [FAVORITE_TYPES.group]: generateIdsObj(participant.participantGroups),
      [FAVORITE_TYPES.category]: event.eventCategoriesById,
    };
    const ids = {
      [FAVORITE_TYPES.participant]: [],
      [FAVORITE_TYPES.group]: [],
      [FAVORITE_TYPES.category]: [],
    };

    favorites.forEach((fav) => {
      const type = fav.favoritable_type;
      const id = fav.favoritable_id;
      if (typed[type] && typed[type][id]) {
        const list = typed[type][id];
        list && ids[type].push(...recursivelyGetProps([list]));
      }
    });
    return ids;
  },
  [GET_CHANNEL_SLIDES_FOR_QUERY]: (state) => (type, query) => {
    let targetArray;
    if (type === SLIDES_TYPES.category) {
      targetArray = Object.values(state.event.eventCategoriesById);
    }
    if (type === SLIDES_TYPES.group) {
      targetArray = state.participant.participantGroups;
    }
    if (type === SLIDES_TYPES.participant) {
      targetArray = state.participant.participantsList;
    }
    const id = targetArray.find(({name, alias}) => (
      alias?.toLowerCase() === query?.toLowerCase() ||
        name?.toLowerCase() === query?.toLowerCase()
    ))?.id;
    return ({...state.channel.slides})[type]?.[id] || [];
  },
};
