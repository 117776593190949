import mutations from './mutations';
import actions from './actions';

export const initialState = {
  favorites: [],
};

export default {
  state: () => ({...initialState}),
  mutations,
  actions,
};
