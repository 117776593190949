import {
  DELETE_CLIP,
  DOWNLOAD_CLIP,
  GET_ALL_CLIPS,
  GET_ALL_CLIPS_FOR_ID,
  GET_ALL_EVENTS_WITH_CLIPS,
  GET_SHAREDCLIP,
  SEND_CLIP,
} from '../actionTypes';
import api from '../../api/api';
import {UPDATE_CLIPS, UPDATE_ALL_CLIPS} from '../mutationTypes';

export default {
  [SEND_CLIP]: async ({dispatch}, {
    event,
    name,
    start,
    duration,
    tags,
  }) => {
    const params = {
      event_id: event,
      name,
      start_time: start,
      duration,
      tags,
    };

    const request = await api.clipApi.send_clip(params);
    dispatch(GET_ALL_CLIPS_FOR_ID, {
      id: event,
    });
    return request;
  },
  [GET_ALL_CLIPS_FOR_ID]: async ({commit}, params) => {
    const response = await api.clipApi.get_clips_for_id(params);
    commit(UPDATE_CLIPS, response);
    return response;
  },
  [DOWNLOAD_CLIP]: (_, params) => {
    return api.clipApi.downloadClip(params);
  },
  [GET_SHAREDCLIP]: (_, params) => {
    return api.clipApi.get_sharedclip(params);
  },
  [DELETE_CLIP]: async ({dispatch}, params) => {
    return await api.clipApi.delete_clip(params);
  },
  [GET_ALL_CLIPS]: async ({commit}) => {
    const {data} = await api.clipApi.get_all_clips();
    commit(UPDATE_ALL_CLIPS, data || []);
  },
  [GET_ALL_EVENTS_WITH_CLIPS]: () => {
    return api.clipApi.get_events_with_clips();
  },
};
